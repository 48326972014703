<template>
  <el-dialog class="js-dialog-form js-dialog-usageMonth" title="月账单" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700px">
    <div class="js-common-content">
      <el-table class="js-table" :data="tableData" border empty-text="暂无数据" size="mini">
        <el-table-column prop="month" label="月份"></el-table-column>
        <el-table-column prop="totalRtcTime" label="通话总时长（分钟）"></el-table-column>
      </el-table>
    </div>
    <common-pagination v-model="pageOptions" @curPageChange="handleCurPageChange"></common-pagination>
  </el-dialog>
</template>

<script>
import CommonPagination from '@/components/CommonPagination'
export default {
  name: '',
  components:{
    CommonPagination
  },
  props: ['value'],
  data() {
    return {
      tableData: [],
      pageOptions:{
        page: 1,
        perPage: 20,
        total: 0
      }
    }
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  watch:{
    dialogVisible(val){
      if(val){
        this.getList()
      }
    }
  },
  mounted() {},
  methods: {
    getList(){
      this.$axios({
        method: 'get',
        url: 'api/csp/mgr/v1/usage/rtcYear',
        params: {
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.tableData = data.data.rows
          this.pageOptions.total = data.data.total
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    // 分页
    handleCurPageChange(){
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>