<template>
  <div class="js-common-wrap js-system-wrap">
    <div class="js-common-head">
      <label>使用情况</label>
    </div>
    <div class="js-common-content js-usage-content">
      <ul class="usage-items">
        <li>
          <h4>有效期</h4>
          <p>年月日</p>
          <h3>{{ baseInfo.userCutOfDate }}</h3>
        </li>
        <li>
          <h4>账户总量<label @click="handleAccount">查看统计<i class="el-icon-arrow-right"></i></label></h4>
          <p>已激活/总数量</p>
          <h3>{{ baseInfo.userCount }} / {{ baseInfo.maxUserCount }}</h3>
        </li>
        <!-- <li>
          <h4>通讯余量<label @click="monthAccount">月账单</label></h4>
          <p>单位：分钟</p>
          <h3>{{ baseInfo.useRtcTime }} / {{ baseInfo.maxRtcTime }}</h3>
        </li>
        <li>
          <h4>存储用量</h4>
          <p>单位：MB</p>
          <h3>{{ baseInfo.useCosSize }} / {{ baseInfo.maxCosSize }}</h3>
        </li> -->
        <li>
          <h4>装机量<label @click="handleInstallAccount">查看统计<i class="el-icon-arrow-right"></i></label></h4>
          <p>单位：台</p>
          <h3>{{ baseInfo.useDeviceCount }} / {{ baseInfo.maxDeviceCount }}</h3>
        </li>
        <li>
          <h4>在线用户</h4>
          <p>单位：人</p>
          <h3>{{ baseInfo.userOnlineCount }}</h3>
        </li>
        <!-- <li>
          <h4>开发者</h4>
          <p>APPID</p>
          <h3>APPsecret</h3>
        </li> -->
      </ul>
      <div class="usage-echarts">
        <div>
          <label>请选择月份：</label>
          <el-date-picker v-model="dateTime" type="month" placeholder="选择月" size="small" @change="changeMonth"></el-date-picker>
        </div>
        <ul class="list">
          <li><label>通话次数<i class="num"></i></label><p>{{ currentBarInfo.totalRtcCount }}</p></li>
          <li><label>用户数<i class="times"></i></label><p>{{ currentBarInfo.totalUserCount }}</p></li>
        </ul>
        <div class="echarts-box" >
          <template v-if="showBarChart">
            <echarts-component :options="barChart" @barParams="barParams"></echarts-component>
          </template>
        </div>
      </div>
    </div>
    <system-usage-month v-model="monthDialogVisible"></system-usage-month>
  </div>
</template>
<script>
import EchartsComponent from '@/components/EchartsComponent'
import SystemUsageMonth from '@/components/SystemUsageMonth'
export default {
  name: '',
  components: {
    SystemUsageMonth,
    EchartsComponent
  },
  data() {
    return {
      monthDialogVisible: false,
      baseInfo: {
        userCutOfDate: '',
        userCount: 0,
        maxUserCount: 0,
        useRtcTime: 0,
        maxRtcTime: 0,
        useCosSize: 0,
        maxCosSize: 0,
        useDeviceCount: 0,
        maxDeviceCount: 0,
        maxConcurrency: 0
      },
      barChart: {
        xAxis: [
          {
            type: 'category',
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value',
            triggerEvent: true
          }
        ],
        series: [
          {
            name: '通话次数',
            type: 'bar',
            barWidth: 10,
            data: []
          },
          {
            name: '通话时长',
            type: 'bar',
            barWidth: 10,
            data: []
          }
        ]
      },
      showBarChart: false,
      dateTime: new Date(),
      monthCount: [],
      currentBarInfo: {
        totalRtcCount: 0,
        totalRtcTime: 0
      }
    }
  },
  mounted() {
    this.getBaseInfo()
    this.getCountByMonth()
  },
  methods: {
    // 获取基本信息
    getBaseInfo(){
      this.$axios({
        method: 'get',
        url: 'api/csp/mgr/v1/usage/'
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.baseInfo = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    // 获取月份统计数据
    getCountByMonth(){
      let year = this.dateTime.getFullYear()
      let m = this.dateTime.getMonth() + 1
      m = m < 10 ? "0" + m : m
      this.showBarChart = false
      this.$axios({
        method: 'get',
        url: `api/csp/mgr/v1/usage/userMonth`,
        params: {
          month: `${year}-${m}`
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          let list = data.data.rows
          this.monthCount = list
          if(list.length){
            let xAxis = []
            let y1 = []
            let y2 = []
            this.currentBarInfo = list[0]
            for(let i in list){
              xAxis.push(list[i].day)
              y1.push(list[i].totalRtcCount)
              y2.push(list[i].totalUserCount)
            }
            this.barChart.xAxis[0].data = xAxis
            this.barChart.series[0].data = y1
            this.barChart.series[1].data = y2
            this.showBarChart = true
          }
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    changeMonth(){
      if(this.dateTime){
        this.getCountByMonth()
      }
    },
    barParams(params){
      this.currentBarInfo = this.monthCount[params.dataIndex]
    },
    handleAccount(){
      this.$router.push({
        path: '/system/usage/account',
      })
    },
    handleInstallAccount(){
      this.$router.push({
        path: `/system/usage/installAccount`,
      })
    },
    monthAccount(){
      this.monthDialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/system.scss'
</style>
