var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-system-wrap" },
    [
      _vm._m(0),
      _c("div", { staticClass: "js-common-content js-usage-content" }, [
        _c("ul", { staticClass: "usage-items" }, [
          _c("li", [
            _c("h4", [_vm._v("有效期")]),
            _c("p", [_vm._v("年月日")]),
            _c("h3", [_vm._v(_vm._s(_vm.baseInfo.userCutOfDate))]),
          ]),
          _c("li", [
            _c("h4", [
              _vm._v("账户总量"),
              _c("label", { on: { click: _vm.handleAccount } }, [
                _vm._v("查看统计"),
                _c("i", { staticClass: "el-icon-arrow-right" }),
              ]),
            ]),
            _c("p", [_vm._v("已激活/总数量")]),
            _c("h3", [
              _vm._v(
                _vm._s(_vm.baseInfo.userCount) +
                  " / " +
                  _vm._s(_vm.baseInfo.maxUserCount)
              ),
            ]),
          ]),
          _c("li", [
            _c("h4", [
              _vm._v("装机量"),
              _c("label", { on: { click: _vm.handleInstallAccount } }, [
                _vm._v("查看统计"),
                _c("i", { staticClass: "el-icon-arrow-right" }),
              ]),
            ]),
            _c("p", [_vm._v("单位：台")]),
            _c("h3", [
              _vm._v(
                _vm._s(_vm.baseInfo.useDeviceCount) +
                  " / " +
                  _vm._s(_vm.baseInfo.maxDeviceCount)
              ),
            ]),
          ]),
          _c("li", [
            _c("h4", [_vm._v("在线用户")]),
            _c("p", [_vm._v("单位：人")]),
            _c("h3", [_vm._v(_vm._s(_vm.baseInfo.userOnlineCount))]),
          ]),
        ]),
        _c("div", { staticClass: "usage-echarts" }, [
          _c(
            "div",
            [
              _c("label", [_vm._v("请选择月份：")]),
              _c("el-date-picker", {
                attrs: { type: "month", placeholder: "选择月", size: "small" },
                on: { change: _vm.changeMonth },
                model: {
                  value: _vm.dateTime,
                  callback: function ($$v) {
                    _vm.dateTime = $$v
                  },
                  expression: "dateTime",
                },
              }),
            ],
            1
          ),
          _c("ul", { staticClass: "list" }, [
            _c("li", [
              _vm._m(1),
              _c("p", [_vm._v(_vm._s(_vm.currentBarInfo.totalRtcCount))]),
            ]),
            _c("li", [
              _vm._m(2),
              _c("p", [_vm._v(_vm._s(_vm.currentBarInfo.totalUserCount))]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "echarts-box" },
            [
              _vm.showBarChart
                ? [
                    _c("echarts-component", {
                      attrs: { options: _vm.barChart },
                      on: { barParams: _vm.barParams },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
      _c("system-usage-month", {
        model: {
          value: _vm.monthDialogVisible,
          callback: function ($$v) {
            _vm.monthDialogVisible = $$v
          },
          expression: "monthDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "js-common-head" }, [
      _c("label", [_vm._v("使用情况")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [_vm._v("通话次数"), _c("i", { staticClass: "num" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [_vm._v("用户数"), _c("i", { staticClass: "times" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }