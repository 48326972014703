var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form js-dialog-usageMonth",
      attrs: {
        title: "月账单",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "js-common-content" },
        [
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.tableData,
                border: "",
                "empty-text": "暂无数据",
                size: "mini",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "month", label: "月份" },
              }),
              _c("el-table-column", {
                attrs: { prop: "totalRtcTime", label: "通话总时长（分钟）" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("common-pagination", {
        on: { curPageChange: _vm.handleCurPageChange },
        model: {
          value: _vm.pageOptions,
          callback: function ($$v) {
            _vm.pageOptions = $$v
          },
          expression: "pageOptions",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }