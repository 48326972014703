<template>
  <div class="charts-wrap" :class="className" ref="chartsDom"></div>
</template>

<script>
export default {
  name: 'EchartsComponent',
  props: {
    className: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: {}
    }
  },
  mounted(){
    const el = this.$refs.chartsDom
    this.myInitCharts(el, this.options)
  },
  methods: {
    myInitCharts(el, options){
      let myCharts = this.$echarts.init(el,'dark')
      myCharts.setOption(options,true)
      window.addEventListener('resize',() => {
        myCharts.resize()
      })
      myCharts.on('click', (params) =>{
        this.$emit('barParams',params)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/echarts.scss'
</style>
